import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Link, graphql } from "gatsby"

class Home extends Component {
  render() {
    const data = this.props.data

    return (
        <Layout>
            <SEO/>

            <div className="main-content">
            
                {data.allWordpressPost.edges.map(({ node }) => (
                    <article key={node.slug}>
                        <h2 className="title">
                            <Link to={node.slug}>
                                {node.title}
                            </Link>
                        </h2>
                        <small className="meta">{node.date}</small>
                        <div className="summary" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </article>
                ))}

            </div>

        </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql `
  query {
    allWordpressPost{
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`