import React from "react"
import logo from "../assets/art/zugrina.jpeg"

export default () => (
    <aside className="sidebar">
        <ul>
            <li className="widget">
                <h1 className="site-logo">
                    <a className="logo-avatar" href="/" title="zugrina">
                        <img src={logo} alt="zugrina" />
                    </a>
                    <a href="/">zugrina</a>
                </h1>
                <h4>Software developer</h4>
                <div className="social-icons">
                    <a title="Follow me on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/zugrina"><i className="icon-twitter"></i></a>
                    <a title="Add me on LinkedIn" target="_blank" rel="noopener noreferrer" href="http://www.linkedin.com/in/zugrina"><i className="icon-linkedin"></i></a>
                </div>
                <p>Working daily with PHP (WordPress) and JavaScript. This is my personal site.</p>
            </li>
        </ul>
    </aside>
)